<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class=" container-fluid p-4">

            <!--                建立表单-->
            <b-card bg-variant="light" style="background-color:white!important;">
                <div style="position:relative;bottom: 35px;background-color:transparent;font-weight: bolder;font-size: 20px">Group Name</div>
                <!--                <b-form action="hotellist">-->
                <b-form>
                    <b-form-group
                            label-cols-lg="12"
                            label=""
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                            class="mb-0">
                        <b-form-input  placeholder="Where are you going?"
                                       v-model="hotel_destination"
                                       required></b-form-input>
                        <b-row style="margin-top: 30px">
                            <b-col md="3">
                                <b-form-datepicker id="check_in_date" v-model="check_in_date" class="mb-2" required></b-form-datepicker>
                            </b-col>
                            <b-col md="3">
                                <b-form-datepicker id="check_out_date" v-model="check_out_date" class="mb-2" required></b-form-datepicker>
                            </b-col>
                            <b-col md="2">
                                <b-form-select v-model="room_selected" :options="room_options" required></b-form-select>
                            </b-col>
                            <b-col md="2">
                                <b-form-select v-model="adult_selected" :options="adult_options" required></b-form-select>
                            </b-col>
                            <b-col md="2">
                                <b-form-select v-model="child_selected" :options="child_options" required></b-form-select>
                            </b-col>

                        </b-row>
                        <b-row>
                            <b-col offset-md="5">
                                <b-button variant="secondary" offset-md="4" @click="HotelCitySearch()" type="submit" to="/cruise/list">Search For Cruise</b-button>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-card>
        </div>
        <div class="c_wrap container-fluid p-4" style="margin-top: 10%" >
            <div class="c_carousel"></div>
            <carousel-3d
                    :autoplay="true"
                    :autoplayTimeout="3000"
                    :display="5"
                    :width="480"
                    :height="330"
                    :animationSpeed="1000"
            >

                <slide class="carousel_box" v-for="(item, index) in earthData" :key="index" :index="index">
                    <div class="carousel_flashlight">
                        <img :src="item.url" alt="" style="min-width: 500px;min-height: 500px">
                    </div>
                </slide>
            </carousel-3d>
        </div>
    </Layout>
</template>

<script>
    // import Customizer from '../../../layouts/full-layout/Customizer/Customizer'
    // import VerticalSidebar from '../../../layouts/full-layout/vertical-sidebar/VerticalSidebar'
    // import VerticalHeader from '../../../layouts/full-layout/vertical-header/VerticalHeader'

    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";

    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import {mapState} from "vuex";
    import hotel from '../../state/modules/hotel'

    export default {
        name: "CruiseHome",
        components:{
            // VerticalSidebar,
            // Customizer,
            // VerticalHeader,
            Layout,
            PageHeader,
            Carousel3d,
            Slide
        },
        data(){
            return{
                title: "Cruise",
                items: [
                    {
                        // #TODO
                        text: "Jebsen Travel",
                    },
                    {
                        text: "Cruise",
                        active: true,
                    },
                ],
                check_in_date:'',
                check_out_date:'',
                hotel_destination:'',
                room_selected:1,
                room_options:[
                    {
                        value:1,text:'1 Room'
                    },
                    {
                        value:2,text:'2 Rooms'
                    },
                    {
                        value:3,text:'3 Rooms'
                    },
                    {
                        value:4,text:'4 Rooms'
                    }
                ],
                adult_selected:1,
                adult_options:[
                    {
                        value:1,text:'1 Adult'
                    },
                    {
                        value:2,text:'2 Adults'
                    },
                    {
                        value:3,text:'3 Adults'
                    },
                    {
                        value:4,text:'4 Adults'
                    }
                ],
                child_selected:0,
                child_options:[
                    {
                        value:0,text:'No Child'
                    },
                    {
                        value:1,text:'1 Child'
                    },
                    {
                        value:2,text:'2 Children'
                    },
                    {
                        value:3,text:'3 Children'
                    },
                    {
                        value:4,text:'4 Children'
                    }
                ],
                earthData: [
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/17/177890/177890a_hb_r_001.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.roomstays.travel/www.miki.co.uk/live/hotel/mikiNet/image/v1.0/GB/20195/43/lr/1ex.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/17/177890/177890a_hb_r_001.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.roomstays.travel/www.miki.co.uk/live/hotel/mikiNet/image/v1.0/GB/20195/43/lr/1ex.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                ],
            }
        },
        methods:{
            // Hotel_Search(){
            //     // 进行post 请求
            //     axios.post(url,{firstName:'Fred',lastName:'Flintstone'}).then(function (response) {
            //         console.log(response);
            //     }).catch(function (error) {
            //         console.log(error);
            //     });
            // },
            HotelCitySearch(){
                console.log(this.check_in_date);
                console.log(this.check_out_date);
                console.log(this.hotel_destination);
                console.log(this.room_selected);
                console.log(this.adult_selected);
                console.log(this.child_selected);
                // this.$router.push({path:'/hotellist'});
            },
            //根据电脑分辨率设置轮播图宽度
            changeWidth() {
                if (
                    document.body.clientWidth >= 1700 &&
                    document.body.clientWidth < 1920
                ) {
                    this.cWidth = 260;
                }
                if (
                    document.body.clientWidth >= 1500 &&
                    document.body.clientWidth < 1700
                ) {
                    this.cWidth = 220;
                }
                if (document.body.clientWidth < 1500) {
                    this.cWidth = 180;
                }
            }
        },
        computed:{
            ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
            products: state => state.products.all,

        },
        created(){
            this.changeWidth();
            console.log(hotel.state());
            console.log(hotel.actions);
            console.log(hotel.getters);
            console.log(hotel.mutations);
        },
        mounted() {
            window.onresize = () => {
                this.changeWidth();
            };
        },

    }
</script>

<style scoped>

</style>